import React, { useState } from "react"
import Header from "../components/header"
import { Link } from "gatsby"

export default () => {
  const [pressed, setPressed] = useState(0)
  return (
    <>
      <Header title={"Home"} />
      <div>Morenooooooooo</div>
      <div>{pressed}</div>
      <button onClick={() => setPressed(pressed + 1)}>Dali +1</button>
      <Link to="/contact">Contact Link</Link>
    </>
  )
}
